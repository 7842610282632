<template>
  <date-picker
    v-model="model"
    v-bind="{ ...$attrs }"
    :inline="true"
    @change="$emit('on:calendar-change', model)"
  />
</template>
<script>

export default {
  data:() => ({
    model: null
  }),
};
</script>

<style lang="scss" src="@lazy/_vue2-datepicker.scss" scoped/>
